import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { Card, Col, Divider, Row, Spin, Tag } from 'antd';
import { BUTTON_AS_LINK } from 'common/constants';
import { formatNumber, scrollToTop } from 'common/utils';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { setFromDate, setSelectedStressEventId } from 'redux/slices/filterSlice';
import { useAppDispatch } from 'redux/store';

const labelClassName = 'flex justify-between min-h-8 min-w-48';

const FishHealthCondition = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const { data: eventFishHealth, loading: eventFishHealthLoading = false } = useSelector(
        (s) => s.eventFishHealth
    );
    const { loading: eventDetailLoading = false } = useSelector((s) => s.eventDetail);
    const { fromDate } = useSelector((s) => s.filter.data);

    const handleLastEventClick = () => {
        const { lastEventId, lastEventStopTime } = eventFishHealth.treatment;
        if (!lastEventId || !lastEventStopTime) return;

        scrollToTop();

        const stopTime = moment(lastEventStopTime);

        dispatch(setSelectedStressEventId(lastEventId));

        if (fromDate.isAfter(stopTime)) dispatch(setFromDate(stopTime.startOf('day')));
    };

    return (
        <div className="pt-2 h-full">
            <Card className="h-full">
                <Spin spinning={!eventDetailLoading && eventFishHealthLoading}>
                    <p className="font-medium text-base">
                        {t('eventDetail.fishHealthCondition.title')}
                    </p>

                    <Row gutter={24}>
                        <Col xs={24} sm={12}>
                            <FishHealthMortality
                                title={t('eventDetail.fishHealthCondition.mortalityBeforeEvent')}
                                fishHealth={eventFishHealth.fishHealthBeforeEvent}
                            />

                            <FishHealthMortality
                                title={t('eventDetail.fishHealthCondition.mortalityAfterEvent')}
                                fishHealth={eventFishHealth.fishHealthAfterEvent}
                            />
                        </Col>

                        <Col xs={24} sm={12}>
                            <Divider orientation="center" className="!mb-0">
                                <p className="font-medium text-sm">
                                    {t('eventDetail.fishHealthCondition.treatment')}
                                </p>
                            </Divider>

                            <div className="flex flex-col items-center">
                                <div className="text-base">
                                    <div className={labelClassName}>
                                        <span className="text-gray-500 mr-1">
                                            {t('eventDetail.fishHealthCondition.daysSinceLast')}
                                        </span>
                                        <span className="font-semibold text-base">
                                            {formatNumber(
                                                eventFishHealth.treatment?.lastEventDays
                                            ) ?? t('eventDetail.fishHealthCondition.noEvent')}
                                        </span>
                                    </div>
                                    <div className={labelClassName}>
                                        <span className="text-gray-500 mr-1">
                                            {t('eventDetail.fishHealthCondition.lastTreatment')}
                                        </span>
                                        <span className="font-semibold text-base">
                                            {eventFishHealth.treatment?.lastEventId ? (
                                                <button
                                                    className={BUTTON_AS_LINK}
                                                    onClick={handleLastEventClick}
                                                >
                                                    {t('eventDetail.fishHealthCondition.visit')}
                                                </button>
                                            ) : (
                                                t('eventDetail.fishHealthCondition.noEvent')
                                            )}
                                        </span>
                                    </div>
                                    <div className={labelClassName}>
                                        <span className="text-gray-500 mr-1">
                                            {t(
                                                'eventDetail.fishHealthCondition.averageDaysBetween'
                                            )}
                                        </span>
                                        <span className="font-semibold text-base">
                                            {formatNumber(
                                                eventFishHealth.treatment?.avgEventDaysOnPen
                                            )}
                                        </span>
                                    </div>
                                    <div className={labelClassName}>
                                        <span className="text-gray-500 mr-1">
                                            {t(
                                                'eventDetail.fishHealthCondition.numberOfTreatments'
                                            )}
                                        </span>
                                        <span className="font-semibold text-base">
                                            {formatNumber(
                                                eventFishHealth.treatment?.totalEventOnPen
                                            )}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Spin>
            </Card>
        </div>
    );
};

const FishHealthMortality = ({ title = '', fishHealth = {} }) => {
    const { t } = useTranslation();
    const { level, levelColor, trend, trendColor } = fishHealth;

    return (
        <div className="mb-4">
            <Divider orientation="center" className="!mb-0">
                <p className="font-medium text-sm">{title}</p>
            </Divider>

            <div className="flex flex-col items-center">
                <div className={`${labelClassName} items-center`}>
                    <span className="text-gray-500 text-base py-1 mr-1">
                        {t('eventDetail.fishHealthCondition.level')}
                    </span>
                    <Tag color={levelColor} className="font-semibold text-base w-20 text-center">
                        {formatNumber(level)}
                    </Tag>
                </div>

                <div className={`${labelClassName} items-center`}>
                    <span className="text-gray-500 text-base py-1 mr-1">
                        {t('eventDetail.fishHealthCondition.trend')}
                    </span>
                    {trend != null ? (
                        <Tag
                            color={trendColor}
                            className="font-semibold text-base w-20 text-center"
                        >
                            {trend < 0 ? <ArrowDownOutlined /> : <ArrowUpOutlined />}
                            {formatNumber(Math.abs(trend))}%
                        </Tag>
                    ) : (
                        <span className="italic w-36 text-center">
                            {t('eventDetail.fishHealthCondition.cannotCalculateTrend')}
                        </span>
                    )}
                </div>
            </div>
        </div>
    );
};

FishHealthMortality.propTypes = {
    title: PropTypes.string,
    fishHealth: PropTypes.object
};

export default FishHealthCondition;
