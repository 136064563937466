import { useMsal } from '@azure/msal-react';
import { Avatar, Button, Card, Col, Descriptions, Image, Row, Spin } from 'antd';
import BgProfile from 'assets/Bg_Profile.jpg';
import defaultAvatar from 'assets/Default_Avatar.png';
import defaultCompanyLogo from 'assets/Default_Company_Logo.jpg';
import { withPageViewTracking } from 'hoc';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import UpdateProfile from './UpdateProfile';
import './index.scss';

const Profile = () => {
    const { t } = useTranslation();
    const { accounts } = useMsal();

    const { data: profile, loading } = useSelector((s) => s.profile);

    const [isModalVisible, setIsModalVisible] = useState(false);

    return (
        <div className="profile">
            <div className="profile-nav-bg" style={{ backgroundImage: 'url(' + BgProfile + ')' }} />
            <Spin spinning={loading}>
                <Card
                    className="card-profile-head"
                    bodyStyle={{ display: 'none' }}
                    title={
                        <Row justify="space-between" align="middle" gutter={[24, 0]}>
                            <Col span={24} md={12} className="col-info">
                                <Avatar.Group>
                                    <Avatar
                                        size={74}
                                        shape="square"
                                        src={profile.avatar || defaultAvatar}
                                    />

                                    <div className="avatar-info">
                                        <h4 className="font-semibold m-0">{profile.name}</h4>
                                        <p>{accounts[0]?.idTokenClaims?.roles}</p>
                                    </div>
                                </Avatar.Group>
                            </Col>
                            <Col
                                span={24}
                                md={12}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-end'
                                }}
                            >
                                <Image
                                    className="object-contain"
                                    width={74}
                                    height={74}
                                    shape="circle"
                                    src={profile.company?.logoUrl || defaultCompanyLogo}
                                />
                            </Col>
                        </Row>
                    }
                />

                <Row gutter={[24, 0]} className="justify-center">
                    <Col span={24} xs={24} sm={24} md={24} lg={12} xl={12} className="mb-8">
                        <Card
                            bordered={false}
                            loading={loading}
                            title={
                                <h6 className="font-semibold m-0">
                                    {t('profile.information.profileTitle')}
                                </h6>
                            }
                            className="header-solid h-full card-profile-information"
                            extra={
                                <Button onClick={() => setIsModalVisible(true)} type="link">
                                    <svg
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        key={0}
                                    >
                                        <path
                                            d="M13.5858 3.58579C14.3668 2.80474 15.6332 2.80474 16.4142 3.58579C17.1953 4.36683 17.1953 5.63316 16.4142 6.41421L15.6213 7.20711L12.7929 4.37868L13.5858 3.58579Z"
                                            className="fill-gray-7"
                                        />
                                        <path
                                            d="M11.3787 5.79289L3 14.1716V17H5.82842L14.2071 8.62132L11.3787 5.79289Z"
                                            className="fill-gray-7"
                                        />
                                    </svg>
                                </Button>
                            }
                            bodyStyle={{ paddingTop: 0, paddingBottom: 16 }}
                        >
                            <p className="text-dark">
                                {profile.bio || (
                                    <span className="text-neutral-400 italic">
                                        {t('profile.information.notUpdatedYet')}
                                    </span>
                                )}
                            </p>
                            <hr className="my-25" />
                            <Descriptions title={t('profile.information.generalTitle')}>
                                <Descriptions.Item
                                    label={t('profile.information.fullName')}
                                    span={3}
                                >
                                    {profile.name || (
                                        <span className="text-neutral-400 italic">
                                            {t('profile.information.notUpdatedYet')}
                                        </span>
                                    )}
                                </Descriptions.Item>
                                <Descriptions.Item
                                    label={t('profile.information.company')}
                                    span={3}
                                >
                                    {profile.company?.name || (
                                        <span className="text-neutral-400 italic">
                                            {t('profile.information.notUpdatedYet')}
                                        </span>
                                    )}
                                </Descriptions.Item>
                                <Descriptions.Item
                                    label={t('profile.information.phoneNumber')}
                                    span={3}
                                >
                                    {profile.phoneNumber || (
                                        <span className="text-neutral-400 italic">
                                            {t('profile.information.notUpdatedYet')}
                                        </span>
                                    )}
                                </Descriptions.Item>
                                <Descriptions.Item label={t('profile.information.email')} span={3}>
                                    {profile.email || (
                                        <span className="text-neutral-400 italic">
                                            {t('profile.information.notUpdatedYet')}
                                        </span>
                                    )}
                                </Descriptions.Item>
                                <Descriptions.Item
                                    label={t('profile.information.address')}
                                    span={3}
                                >
                                    {profile.address || (
                                        <span className="text-neutral-400 italic">
                                            {t('profile.information.notUpdatedYet')}
                                        </span>
                                    )}
                                </Descriptions.Item>
                            </Descriptions>
                        </Card>
                    </Col>
                </Row>
            </Spin>

            <UpdateProfile visible={isModalVisible} onClose={() => setIsModalVisible(false)} />
        </div>
    );
};

export default withPageViewTracking(Profile, 'Profile');
