import { Image } from 'antd';
import PropTypes from 'prop-types';

const WoundAndSizeAttachment = ({ attachments = [], limit = 2 }) => {
    return (
        <div className="flex flex-wrap gap-1">
            {attachments.slice(0, limit).map((attachment, index) => (
                <Image
                    className="rounded-sm border-inherit object-contain border border-solid border-slate-400"
                    width={60}
                    height={60}
                    src={attachment}
                    key={index}
                />
            ))}
        </div>
    );
};

WoundAndSizeAttachment.propTypes = {
    attachments: PropTypes.array,
    limit: PropTypes.number
};

export default WoundAndSizeAttachment;
