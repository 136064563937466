import { appInsights } from 'AppInsights';
import { FDFilter } from 'components';
import { FiltersContext } from 'context/filtersContext';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { NotFound } from 'pages';
import { useCallback, useEffect, useState } from 'react';
import { useAppDispatch } from 'redux/store';
import { getFlowReports, getPensSelection, getSitesSelection } from 'redux/thunks';
import ReportTable from './ReportTable';

const Flow = () => {
    const dispatch = useAppDispatch();

    const { showFlow } = useFlags();

    const [filters, setFilters] = useState({});

    useEffect(() => {
        dispatch(getPensSelection());
        dispatch(getSitesSelection());
    }, []);

    const handleFilterSubmit = useCallback((query) => {
        setFilters(query);

        dispatch(getFlowReports(query));

        appInsights.trackEvent({ name: 'Flow filter apply' });
    }, []);

    if (!showFlow) return <NotFound />;

    return (
        <>
            <FDFilter hasSite hasPen hasFishName hasDateRange callback={handleFilterSubmit} />

            <FiltersContext.Provider value={filters}>
                <ReportTable />
            </FiltersContext.Provider>
        </>
    );
};

export default Flow;
