import { appInsights } from 'AppInsights';
import { formatDateTime } from 'common/utils';
import { FDFilter, FDSliderSelection } from 'components';
import { useDidMountEffect } from 'hooks/useDidMountEffect';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { setSelectedFishGroupId } from 'redux/slices/filterSlice';
import { useAppDispatch } from 'redux/store';
import {
    getFishGroupTreeByFishwellFishGroupId,
    getFishwellFishIdsSelection,
    getPensSelection,
    getSitesSelection
} from 'redux/thunks';

const FilterBar = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { data: fishwellFishIdsSelection, loading } = useSelector(
        (s) => s.fishwellFishIdSelection
    );

    const { selectedFishGroupId } = useSelector((s) => s.filter.data);

    useEffect(() => {
        dispatch(getSitesSelection());
        dispatch(getPensSelection());
    }, []);

    useDidMountEffect(() => {
        handleFishwellFishIdChange(
            (fishwellFishIdsSelection.some((e) => e.id === selectedFishGroupId) &&
                selectedFishGroupId) ||
                fishwellFishIdsSelection[0]?.id ||
                null
        );
    }, [fishwellFishIdsSelection]);

    const handleFishwellFishIdChange = (selectedFishGroupId) => {
        dispatch(setSelectedFishGroupId(selectedFishGroupId));

        if (selectedFishGroupId)
            dispatch(getFishGroupTreeByFishwellFishGroupId(selectedFishGroupId));

        appInsights.trackEvent({
            name: 'Selected fish group change',
            properties: { selectedFishGroupId }
        });
    };

    const handleFilterSubmit = useCallback((query) => {
        dispatch(getFishwellFishIdsSelection(query));

        appInsights.trackEvent({ name: 'FFI filter apply' });
    }, []);

    return (
        <>
            <FDFilter hasSite hasPen hasDateRange callback={handleFilterSubmit} />

            <div className="filter-bar my-2">
                <div className="filter p-2.5 md:text-base">
                    <div className="font-semibold">{t('general.filterBar.fishGroups')}</div>

                    <FDSliderSelection
                        selectedId={selectedFishGroupId}
                        listSelectItem={fishwellFishIdsSelection.map((item) => ({
                            id: item.id,
                            content: (
                                <div>
                                    <div>{formatDateTime(item.actionDate)}</div>
                                    <div>{item.text}</div>
                                    <div>{item.siteName}</div>
                                    <div>
                                        {t('general.table.penNumber')}: {item.penNumber}
                                    </div>
                                </div>
                            )
                        }))}
                        loading={loading}
                        onSelected={handleFishwellFishIdChange}
                    />
                </div>
            </div>
        </>
    );
};

export default FilterBar;
