import { SwapRightOutlined } from '@ant-design/icons';
import { Button, Form, InputNumber, Modal, Spin } from 'antd';
import { appInsights } from 'AppInsights';
import {
    BUTTON_BLUE_CLASS,
    BUTTON_WHITE_CLASS,
    PRODUCTION_FISH_NAMES,
    SUCCESSFULLY_UPDATE_MESSAGE
} from 'common/constants';
import { alertErrorMessage, alertSuccessMessage, formatNumber } from 'common/utils';
import { FDSelection } from 'components';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import { updateFishMortality } from 'redux/thunks';

const MortalityForm = ({ open = false, fishMortality, onClose = () => {} }) => {
    const [form] = Form.useForm();
    const selectedSiteId = Form.useWatch('siteId', form);
    const selectedPenId = Form.useWatch('penId', form);
    const [fishes, setFishes] = useState([]);
    const [isDirty, setIsDirty] = useState(false);
    const [productionFishName, setProductionFishName] = useState(null);

    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const loading = useSelector((s) => s.mortality.list.loading);
    const sitesSelection = useSelector((s) => s.siteSelection.data);
    const pensSelection = useSelector((s) => s.penSelection.data);

    useEffect(() => {
        form.setFieldsValue(fishMortality);
        setFishes(fishMortality?.fishes || []);
        const productionFish = fishMortality?.fishes.find((fish) => {
            return PRODUCTION_FISH_NAMES.some((type) => type.id === fish?.fishName);
        });
        setProductionFishName(productionFish?.fishName);
    }, [fishMortality]);

    useEffect(() => {
        const dirty = detectIsDirty();
        setIsDirty(dirty);
    }, [form.getFieldsValue()]);

    const handleCancel = () => {
        form.resetFields();
        onClose();
    };

    const detectIsDirty = () => {
        const isFishCountChanged = fishes.some((fish) => {
            const oldDeadFishCount = fishMortality?.fishes.find(
                (f) => f.deadFishDetectionId === fish.deadFishDetectionId
            )?.count;
            return fish.count !== oldDeadFishCount;
        });

        return (
            isFishCountChanged ||
            selectedSiteId !== fishMortality?.siteId ||
            selectedPenId !== fishMortality?.penId
        );
    };

    const handleSiteChange = (siteId, site) => {
        if (!site?.pens?.some((pen) => pen.id === selectedPenId)) {
            form.setFieldValue('penId', null);
        }
    };

    const handleFishCountChange = (fish, count) => {
        form.setFieldValue(fish.fishName, count);
        setFishes(
            fishes.map((f) => {
                if (f.deadFishDetectionId === fish.deadFishDetectionId) {
                    return { ...f, count };
                }
                return f;
            })
        );
    };

    const handleUpdateFishMortality = async (fishMortalityUp) => {
        const fishMortalityUpRequest = {
            deadFishCounts: fishes?.map((count) => ({
                deadFishDetectionId: count.deadFishDetectionId,
                count: count.count
            })),
            siteId: fishMortalityUp.siteId,
            penId: fishMortalityUp.penId,
            productionFishName
        };
        try {
            await dispatch(updateFishMortality(fishMortalityUpRequest)).unwrap();

            alertSuccessMessage(SUCCESSFULLY_UPDATE_MESSAGE);
            form.resetFields();
            onClose();
        } catch (err) {
            alertErrorMessage(err);
        }

        appInsights.trackEvent({
            name: 'Fish mortality update',
            properties: { fishMortalityId: fishMortality?.id }
        });
    };

    return (
        <Modal
            title={t('mortalityList.mortalityForm.updateMortality')}
            open={open}
            onCancel={handleCancel}
            onOk={onClose}
            footer={
                <>
                    <Button type="default" onClick={handleCancel} className={BUTTON_WHITE_CLASS}>
                        {t('general.form.cancel')}
                    </Button>
                    <Button onClick={form.submit} className={BUTTON_BLUE_CLASS} disabled={!isDirty}>
                        {t('general.form.saveChange')}
                    </Button>
                </>
            }
        >
            <Spin spinning={loading}>
                <Form
                    name="basic"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 14 }}
                    onFinish={handleUpdateFishMortality}
                    autoComplete="off"
                    form={form}
                >
                    <Form.Item label={t('general.form.site.title')} name="siteId">
                        <FDSelection
                            placeholder={t('general.form.site.placeholder')}
                            listSelectItem={sitesSelection}
                            onChange={handleSiteChange}
                            allowClear={true}
                            showSearch={true}
                        />
                    </Form.Item>

                    <Form.Item
                        label={t('general.form.pen.title')}
                        name="penId"
                        rules={[
                            {
                                required: true,
                                message: t('general.form.pen.required')
                            }
                        ]}
                    >
                        <FDSelection
                            placeholder={t('general.form.pen.placeholder')}
                            listSelectItem={pensSelection.filter(
                                (pen) => !selectedSiteId || pen.siteId === selectedSiteId
                            )}
                            onChange={(penId, pen) => form.setFieldValue('siteId', pen?.siteId)}
                            allowClear={true}
                            showSearch={true}
                        />
                    </Form.Item>

                    {fishes?.map((fish) => {
                        var isProductionFish = PRODUCTION_FISH_NAMES.reduce((acc, fishName) => {
                            return acc || fishName.id === fish.fishName;
                        }, false);

                        var oldDeadFishCount = fishMortality?.fishes.reduce((acc, f) => {
                            return f.deadFishDetectionId === fish.deadFishDetectionId
                                ? f.count
                                : acc;
                        }, 0);
                        return (
                            <Form.Item
                                key={fish.deadFishDetectionId}
                                label={
                                    isProductionFish ? (
                                        <span onClick={(e) => e.preventDefault()}>
                                            <FDSelection
                                                placeholder={t(
                                                    'otherDeadFishCount.upsertForm.fishName.placeholder'
                                                )}
                                                listSelectItem={PRODUCTION_FISH_NAMES.map(
                                                    (type) => ({
                                                        ...type,
                                                        text: t(type.text)
                                                    })
                                                )}
                                                defaultValue={fish.fishName}
                                                onChange={(value) => setProductionFishName(value)}
                                            />
                                        </span>
                                    ) : (
                                        t(`general.fishMortality.${fish.fishName}`)
                                    )
                                }
                                name={fish.fishName}
                                rules={[
                                    {
                                        required: true,
                                        message: t('mortalityList.mortalityForm.count.required')
                                    },
                                    {
                                        type: 'number',
                                        message: t('mortalityList.mortalityForm.count.invalid')
                                    }
                                ]}
                            >
                                <InputNumber
                                    addonBefore={
                                        <span className="w-20 inline-block flex justify-between">
                                            {formatNumber(oldDeadFishCount)}
                                            <SwapRightOutlined />
                                        </span>
                                    }
                                    min={0}
                                    max={999999}
                                    style={{ minWidth: 200 }}
                                    value={fish.count}
                                    onChange={(number) => handleFishCountChange(fish, number)}
                                />
                            </Form.Item>
                        );
                    })}
                </Form>
            </Spin>
        </Modal>
    );
};

MortalityForm.propTypes = {
    open: PropTypes.bool,
    fishMortality: PropTypes.object,
    onClose: PropTypes.func
};

export default MortalityForm;
