import { ABOVE_AVERAGE, AVERAGE, BELOW_AVERAGE } from 'common/constants';

let numberFormatter;
export const configureNumberFormatter = (locale) => {
    numberFormatter = new Intl.NumberFormat(locale, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 3
    });
};

export const formatNumber = (number, digit) => {
    if (isNaN(number)) {
        return number;
    }

    if (digit) number = Number(number).toFixed(digit);

    return numberFormatter.format(number);
};

export const getMortalityStatus = (deviation = 0) => {
    deviation = deviation * 100;

    if (deviation < 0) {
        return BELOW_AVERAGE;
    } else if (deviation <= 80) {
        return AVERAGE;
    } else {
        return ABOVE_AVERAGE;
    }
};

export const getRandomBoolean = () => {
    const THRESHOLD = 128;

    return crypto.getRandomValues(new Uint8Array(1))[0] < THRESHOLD;
};
