import { Progress } from '@ant-design/plots';
import { Card, Col, Row, Spin } from 'antd';
import { appInsights } from 'AppInsights';
import { BLUE, GRAY, GREEN, SCORING_EVENT_PAGE, SILVER, WHITE, YELLOW } from 'common/constants';
import { formatNumber } from 'common/utils';
import { FDColumn } from 'components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { processWelfareScoreSelector } from 'redux/selector';
import { setSelectedScoringEventId } from 'redux/slices/filterSlice';
import { useAppDispatch } from 'redux/store';

const WelfareScore = ({ hasTitle = true }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { data: welfareScore, loading } = useSelector(processWelfareScoreSelector);

    var welfareScoreLices = welfareScore.lices?.map((lice) => ({
        name: t(`scoringEvent.${lice.name}`),
        type: lice.isBeforeDelicingEvent ? t('process.before') : t('process.after'),
        value: lice.value
    }));

    var welfareScoreConditions = welfareScore.conditions?.map((condition) => ({
        name: t(`scoringEvent.${condition.name}`),
        type: condition.isBeforeDelicingEvent ? t('process.before') : t('process.after'),
        value: condition.value
    }));

    const delicingEffectChartConfig = {
        height: 60,
        percent: (welfareScore.delicingEffect || 0) / 100,
        color: [GREEN, SILVER],
        annotations: [
            {
                type: 'text',
                position: ['50%', '50%'],
                content: `${formatNumber(welfareScore.delicingEffect || 0)}% ${t(
                    'process.delicingEffect'
                )}`,
                style: {
                    textAlign: 'center',
                    fill: welfareScore.delicingEffect > 50 ? WHITE : GRAY,
                    fontSize: 13,
                    fontStyle: 'bold'
                }
            }
        ]
    };

    const handleWelfareScoreChartClick = () => {
        if (!welfareScore.scoringEventIds) return;

        const selectedScoringEventId = welfareScore.scoringEventIds.toString();

        dispatch(setSelectedScoringEventId(selectedScoringEventId));

        navigate(SCORING_EVENT_PAGE);

        appInsights.trackEvent({
            name: 'Scoring event is navigated from process page',
            properties: { eventId: selectedScoringEventId }
        });
    };

    return (
        <Spin spinning={loading}>
            <Card
                className={`mt-2 ${welfareScore.scoringEventIds && 'cursor-pointer'}`}
                onClick={() => handleWelfareScoreChartClick()}
                role="button"
            >
                {hasTitle && <p className="font-medium text-base">{t('process.welfareScore')}</p>}
                <Row>
                    <Col xs={{ span: 14, order: 1 }} xl={{ span: 7, order: 1 }}>
                        <FDColumn
                            data={welfareScoreLices}
                            color={[BLUE, YELLOW]}
                            height={300}
                            legend={false}
                            className="border-0 border-r border-solid border-r-gray-400 px-1"
                        />
                    </Col>

                    <Col
                        xs={{ span: 24, order: 3 }}
                        xl={{ span: 12, order: 2 }}
                        xxl={{ span: 13, order: 2 }}
                    >
                        <FDColumn
                            data={welfareScoreConditions}
                            color={[BLUE, YELLOW]}
                            height={300}
                            legend={false}
                            className="xs:mt-2 xl:mt-0 border-0 xl:border-r border-solid border-r-gray-400 px-1"
                        />
                    </Col>

                    <Col
                        xs={{ span: 10, order: 2 }}
                        xl={{ span: 5, order: 3 }}
                        xxl={{ span: 4, order: 3 }}
                    >
                        <div className="flex justify-center items-center h-[300px]">
                            <div className="flex flex-col justify-center gap-2">
                                <span className="text-base font-medium mb-4">
                                    {t('process.numberOfFishes')}
                                </span>
                                <div>
                                    <span className="h-8 xs:w-10 sm:w-14 mr-2 inline-block bg-sky-700 text-lg text-white font-medium round text-center align-middle rounded">
                                        {formatNumber(welfareScore.numberOfFishesBefore || 0)}
                                    </span>
                                    <span className="text-sm font-medium">
                                        {t('process.preDelicing')}
                                    </span>
                                </div>
                                <div>
                                    <span className="h-8 xs:w-10 sm:w-14 mr-2 inline-block bg-yellow-500 text-lg text-white font-medium round text-center align-middle rounded">
                                        {formatNumber(welfareScore.numberOfFishesAfter || 0)}
                                    </span>
                                    <span className="text-sm font-medium">
                                        {t('process.postDelicing')}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </Col>

                    <Col xs={{ span: 24, order: 4 }}>
                        <Progress {...delicingEffectChartConfig} />
                    </Col>
                </Row>
            </Card>
        </Spin>
    );
};

WelfareScore.propTypes = {
    hasTitle: PropTypes.bool
};

export default WelfareScore;
