import PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';

export const useQueryFromFilters = ({
    hasComparison,
    hasSite,
    hasPen,
    hasFishGroupIds,
    hasServiceBoat,
    hasDateRange,
    hasDate,
    hasFishName,
    hasStressLevelStatus
}) => {
    const query = {};

    const {
        comparison,
        siteIds,
        penIds,
        fishGroupIds: selectedFishGroupIds,
        fishNames,
        serviceBoatIds,
        fromDate,
        toDate,
        daily,
        stressLevelStatus
    } = useSelector((s) => s.filter.data, shallowEqual);

    const addQueryParam = (condition, key, value) => {
        if (condition) {
            query[key] = value;
        }
    };

    addQueryParam(hasComparison, 'siteIds', comparison.siteIds);
    addQueryParam(hasComparison, 'penIds', comparison.penIds);

    addQueryParam(hasSite, 'siteIds', siteIds);
    addQueryParam(hasPen, 'penIds', penIds);
    addQueryParam(hasFishGroupIds, 'fishGroupIds', selectedFishGroupIds);
    addQueryParam(hasServiceBoat, 'serviceBoatIds', serviceBoatIds);
    addQueryParam(hasFishName, 'fishNames', fishNames);

    addQueryParam(hasDateRange, 'fromDate', fromDate);
    addQueryParam(hasDateRange, 'toDate', toDate);

    addQueryParam(hasDate, 'fromDate', daily.fromDate);
    addQueryParam(hasDate, 'toDate', daily.toDate);

    addQueryParam(hasStressLevelStatus, 'stressLevelStatus', stressLevelStatus);

    return query;
};

useQueryFromFilters.propTypes = {
    hasComparison: PropTypes.bool,
    hasSite: PropTypes.bool,
    hasPen: PropTypes.bool,
    hasFishGroupIds: PropTypes.bool,
    hasServiceBoat: PropTypes.bool,
    hasDateRange: PropTypes.bool,
    hasDate: PropTypes.bool,
    hasFishName: PropTypes.bool,
    hasStressLevelStatus: PropTypes.bool
};
