import { RingProgress } from '@ant-design/plots';
import { BLUE, SILVER } from 'common/constants';
import { formatNumber } from 'common/utils';
import PropTypes from 'prop-types';

const FDPercentage = ({ height = 100, width = 100, value = 0, color = [BLUE, SILVER] }) => {
    const config = {
        height,
        width,
        autoFit: false,
        percent: value / 100,
        color: color,
        meta: {
            percent: { formatter: (v) => `${formatNumber(v)}%` }
        }
    };

    return <RingProgress {...config} />;
};

FDPercentage.propTypes = {
    height: PropTypes.number,
    width: PropTypes.number,
    value: PropTypes.number,
    color: PropTypes.array
};

export default FDPercentage;
