import { DualAxes } from '@antv/g2plot';
import { formatNumber } from 'common/utils';
import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

const DualMortalityPercentagesChart = ({ data = [], chartConfig = {} }) => {
    const { t } = useTranslation();

    const chartRef = useRef(null);

    useEffect(() => {
        const columnData = data
            .filter((item) => item.percentages != null)
            .map((item) => ({
                name: item.name,
                percentages: item.percentages
            }));

        const lineData = data.map((item) => ({
            name: item.name,
            type: item.type,
            value: item.value
        }));

        const dualAxes = new DualAxes(chartRef.current, {
            data: [lineData, columnData],
            xField: 'name',
            yField: ['value', 'percentages'],
            geometryOptions: [
                {
                    geometry: 'line',
                    seriesField: 'type',
                    smooth: true
                },
                {
                    geometry: 'column'
                }
            ],
            meta: {
                value: { formatter: (v) => formatNumber(v) },
                percentages: {
                    formatter: (v) => `${formatNumber(v)}%`,
                    alias: t('general.fishMortality.percentages')
                }
            },
            animation: {
                appear: {
                    animation: 'path-in',
                    duration: 2000
                }
            },
            legend: { position: 'top' },
            ...chartConfig
        });

        dualAxes.render();

        return () => {
            dualAxes.destroy();
        };
    }, [data]);

    return <div ref={chartRef} />;
};

DualMortalityPercentagesChart.propTypes = {
    data: PropTypes.array,
    chartConfig: PropTypes.object
};

export default DualMortalityPercentagesChart;
