import { Card, Col, Row, Spin, Statistic } from 'antd';
import { BLUE, RED } from 'common/constants';
import { formatDateTime, formatNumber } from 'common/utils';
import { FDStackedColumn } from 'components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import './index.scss';

const SalmonWound = () => {
    const { t } = useTranslation();

    const { data, loading } = useSelector((s) => s.reportSalmonWound);

    return (
        <div className="salmon-wound-report mt-5">
            <h2 className="mb-2">{t('mortalityAnalysis.salmonWound.title')}</h2>
            <Spin spinning={loading}>
                <Row gutter={[8, 8]}>
                    <Col xs={24} sm={24} md={24} lg={4} xl={4}>
                        <Row gutter={[8, 8]} className="h-full">
                            {data.counts?.map((item, index) => (
                                <Col
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={24}
                                    xl={24}
                                    key={index}
                                    className={item.title}
                                >
                                    <Card className="flex justify-center content-center h-full card-count">
                                        <Statistic
                                            title={t(
                                                `mortalityAnalysis.salmonWound.card.labels.${item.title}`
                                            )}
                                            value={formatNumber(item.totalCount)}
                                            valueStyle={{ color: '#cf1322' }}
                                        />
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    </Col>

                    <Col xs={24} sm={24} md={24} lg={20} xl={20}>
                        <Card>
                            <FDStackedColumn
                                data={data.charts?.flatMap((item) => [
                                    {
                                        name: formatDateTime(item.date),
                                        value: item.numberOfFishWithWounds,
                                        type: t(
                                            'mortalityAnalysis.salmonWound.chart.labels.withWounds'
                                        )
                                    },
                                    {
                                        name: formatDateTime(item.date),
                                        value: item.numberOfFishWithoutWounds,
                                        type: t(
                                            'mortalityAnalysis.salmonWound.chart.labels.withoutWounds'
                                        )
                                    }
                                ])}
                                colors={[RED, BLUE]}
                                formatValue={formatNumber}
                            />
                        </Card>
                    </Col>
                </Row>
            </Spin>
        </div>
    );
};

export default SalmonWound;
