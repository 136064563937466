import { Mix } from '@antv/g2plot';
import { BLACK, BLUE, RANGE_AREA_GREEN } from 'common/constants';
import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';

const FDRangeArea = ({
    data = {},
    tooltipLabel = {},
    chartConfig = {},
    style = {},
    xAxisFormatter = (value) => value,
    yAxisFormatter = (value) => value
}) => {
    const chartRef = useRef(null);

    useEffect(() => {
        const rangeAreaPlot = new Mix(chartRef.current, {
            appendPadding: 0,
            syncViewPadding: true,
            tooltip: {
                shared: true,
                showMarkers: true,
                showCrosshairs: true,
                offsetY: -50,
                customItems: (items) =>
                    items.map((item) => ({
                        ...item,
                        name: item.name,
                        value: Array.isArray(item.data.value)
                            ? item.data.value.map((v) => yAxisFormatter(v)).join(' - ')
                            : yAxisFormatter(item.data.value)
                    }))
            },
            legend: false,
            plots: [
                {
                    type: 'area',
                    options: {
                        data: data.areas || [],
                        xField: 'name',
                        yField: 'value',
                        color: RANGE_AREA_GREEN,
                        meta: {
                            value: {
                                sync: 'value',
                                nice: true,
                                alias: tooltipLabel.area
                            }
                        },
                        legend: true,
                        xAxis: false,
                        yAxis: { label: false, ...chartConfig.yAxis },
                        smooth: true,
                        animation: false
                    }
                },
                {
                    type: 'line',
                    options: {
                        data: data.lines || [],
                        xField: 'name',
                        yField: 'value',
                        color: BLUE,
                        smooth: true,
                        animation: false,
                        xAxis: {
                            label: {
                                style: {
                                    fontSize: 11,
                                    fill: BLACK
                                }
                            },
                            formatter: (value) => xAxisFormatter(value)
                        },
                        yAxis: {
                            label: {
                                style: {
                                    fontSize: 11,
                                    fill: BLACK
                                },
                                formatter: (value) => yAxisFormatter(value)
                            },
                            ...chartConfig.yAxis
                        },
                        meta: {
                            value: {
                                sync: true,
                                alias: tooltipLabel.line
                            }
                        }
                    }
                }
            ]
        });

        rangeAreaPlot.render();

        return () => {
            rangeAreaPlot.destroy();
        };
    }, [data]);

    return <div ref={chartRef} className="h-80" style={style} />;
};

FDRangeArea.propTypes = {
    data: PropTypes.object,
    tooltipLabel: PropTypes.object,
    chartConfig: PropTypes.object,
    style: PropTypes.object,
    xAxisFormatter: PropTypes.func,
    yAxisFormatter: PropTypes.func
};

export default FDRangeArea;
