import { formatDateTime, formatNumber } from 'common/utils';
import { FDTable } from 'components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ComparisonChart from '../ComparisonChart';

const SitesComparison = () => {
    const { t } = useTranslation();

    const { data: siteComparison, loading } = useSelector((s) => s.siteComparison);

    return (
        <div className="sites-comparison mt-5" data-testid="site-comparison">
            <h2 className="mb-2">{t('comparison.sitesComparison')}</h2>
            <ComparisonChart
                loading={loading}
                charts={siteComparison.charts?.map((chart) => ({
                    type: chart.siteName,
                    value: chart.count,
                    name: formatDateTime(chart.countingDate)
                }))}
            />

            <FDTable
                loading={loading}
                headers={
                    siteComparison.tableSites?.length
                        ? [
                              {
                                  id: 1,
                                  title: 'comparison.siteColumn',
                                  dataIndex: 'siteName',
                                  width: '20%'
                              },
                              ...(siteComparison.tableSites[0].fishes?.map((fish) => ({
                                  id: fish.fishName,
                                  title: `general.fishMortality.${fish.fishName}`,
                                  dataIndex: fish.fishName,
                                  key: fish.fishName,
                                  render: (fish) => formatNumber(fish),
                                  width: '20%'
                              })) ?? [])
                          ]
                        : []
                }
                data={siteComparison.tableSites?.map((site) =>
                    site.fishes.reduce(
                        (acc, fish) => {
                            acc[fish.fishName] = fish.count.toString();
                            return acc;
                        },
                        {
                            key: site.siteId,
                            siteName: site.siteName
                        }
                    )
                )}
            />
        </div>
    );
};

export default SitesComparison;
