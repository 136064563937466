import { useMsal } from '@azure/msal-react';
import { appInsights } from 'AppInsights';
import { loginRequest } from 'authConfig';
import { alertErrorMessage, getAuthority } from 'common/utils';
import { FDSelection } from 'components';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import { getAvailableTenants } from 'redux/thunks';

const TenantSelection = () => {
    const { instance, accounts } = useMsal();
    const dispatch = useAppDispatch();

    const { data: tenants, loading } = useSelector((s) => s.tenant);

    useEffect(() => {
        if (accounts.length === 0) return;

        // Explain: accounts[0].homeAccountId = homeAdUserId.homeTenantId
        const homeTenantId = accounts[0].homeAccountId.split('.')[1];

        dispatch(
            getAvailableTenants({
                userEmail: accounts[0].username,
                homeTenantId: homeTenantId
            })
        );
    }, [accounts]);

    const handleTenantChange = async (tenantId) => {
        localStorage.clear();

        const request = {
            scopes: loginRequest.scopes,
            authority: getAuthority(tenantId),
            redirectUri: '/'
        };

        try {
            await instance.loginRedirect(request);
        } catch (error) {
            alertErrorMessage(error);
        }

        appInsights.trackEvent({ name: 'Tenant change', properties: { tenantId } });
    };

    return (
        <FDSelection
            listSelectItem={tenants.map((tenant) => ({
                id: tenant.azureTenantId,
                text: tenant.tenantName
            }))}
            className="min-w-[180px]"
            loading={loading}
            value={loading ? null : accounts[0]?.tenantId}
            onChange={handleTenantChange}
        />
    );
};

export default TenantSelection;
