import { Bar } from '@ant-design/plots';
import PropTypes from 'prop-types';

const FDBar = ({ data = [], colors, height, chartConfig, formatValue = (value) => value }) => {
    const config = {
        data,
        height,
        xField: 'value',
        yField: 'name',
        seriesField: 'name',
        color: colors,
        label: {
            position: 'right'
        },
        appendPadding: [0, 40, 0, 0],
        legend: false,
        xAxis: {
            label: {
                style: {
                    fontSize: 11,
                    fill: 'black'
                }
            }
        },
        yAxis: {
            label: {
                style: {
                    fontSize: 11,
                    fill: 'black'
                }
            }
        },
        meta: {
            value: { formatter: (v) => formatValue(v) }
        },
        ...chartConfig
    };

    return <Bar {...config} />;
};

FDBar.propTypes = {
    data: PropTypes.array,
    colors: PropTypes.array,
    height: PropTypes.number,
    chartConfig: PropTypes.object,
    formatValue: PropTypes.func
};

export default FDBar;
