import { Card } from 'antd';
import { formatSeconds, getDifferenceInSeconds, isTimeWithinSliderValues } from 'common/utils';
import { FDLine } from 'components';
import { flatMap, reduce } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { channelsSelector, eventDetailStartTimeSelector } from 'redux/selector';

const CurrentSpeed = ({ sliderValues }) => {
    const { t } = useTranslation();

    const channels = useSelector(channelsSelector);
    const eventStartTime = useSelector(eventDetailStartTimeSelector);

    const [chartData, setChartData] = useState([]);

    useEffect(() => {
        const filteredData = flatMap(channels, (channel) =>
            reduce(
                channel.currentSpeeds,
                (accumulator, speed) => {
                    const differenceInSeconds = getDifferenceInSeconds(
                        eventStartTime,
                        speed.stop_time
                    );

                    if (isTimeWithinSliderValues(sliderValues, differenceInSeconds))
                        accumulator.push({
                            type: `${t('eventDetail.channel')} ${channel.channelName}`,
                            name: formatSeconds(differenceInSeconds),
                            value: speed.value || 0
                        });

                    return accumulator;
                },
                []
            )
        );

        setChartData(filteredData);
    }, [sliderValues]);

    return (
        <Card className="mt-2">
            <p className="font-medium text-base">{t('eventDetail.currentSpeed')}</p>
            <FDLine data={chartData} height={300} chartConfig={{ animation: false }} />
        </Card>
    );
};

CurrentSpeed.propTypes = {
    sliderValues: PropTypes.array
};

export default CurrentSpeed;
