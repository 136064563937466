import { appInsights } from 'AppInsights';
import { FDFilter } from 'components';
import { FiltersContext } from 'context/filtersContext';
import { withPageViewTracking } from 'hoc';
import { useCallback, useEffect, useState } from 'react';
import { useAppDispatch } from 'redux/store';
import {
    getFishwellFishIdsSelection,
    getPensSelection,
    getReportSalmonWound,
    getSitesSelection
} from 'redux/thunks';
import FishMortality from './FishMortality';
import './MortalityAnalysis.scss';
import ReportTable from './ReportTable';
import SalmonWound from './SalmonWound';
import WoundType from './WoundType';

const MortalityAnalysis = () => {
    const dispatch = useAppDispatch();

    const [filters, setFilters] = useState({});

    useEffect(() => {
        dispatch(getPensSelection());
        dispatch(getSitesSelection());
        dispatch(getFishwellFishIdsSelection());
    }, []);

    const handleFilterSubmit = useCallback((query) => {
        setFilters(query);

        dispatch(getReportSalmonWound(query));

        appInsights.trackEvent({ name: 'Mortality filter apply' });
    }, []);

    return (
        <div className="mortality-analysis">
            <FDFilter
                hasSite
                hasPen
                hasFishName
                hasFishGroupIds
                hasDateRange
                callback={handleFilterSubmit}
            />

            <FiltersContext.Provider value={filters}>
                <FishMortality />

                <SalmonWound />

                <WoundType />

                <ReportTable />
            </FiltersContext.Provider>
        </div>
    );
};

export default withPageViewTracking(MortalityAnalysis, 'Mortality analysis');
