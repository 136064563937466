import { createSlice } from '@reduxjs/toolkit';
import {
    updateFishMortality,
    getMortalityDetail,
    getMortalityDetailList,
    getMortalityList
} from 'redux/thunks';

const initialState = {
    list: { data: [], loading: false },
    detailList: { data: [], loading: false },
    detail: { data: {}, loading: false }
};

export const mortalitySlice = createSlice({
    name: 'mortalitySlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getMortalityList.pending, (state) => {
                state.list.loading = true;
            })
            .addCase(getMortalityList.fulfilled, (state, action) => {
                state.list.loading = false;
                state.list.data = action.payload || initialState.list.data;
            })
            .addCase(getMortalityList.rejected, (state) => {
                state.list.loading = false;
                state.list.data = initialState.list.data;
            })

            .addCase(getMortalityDetailList.pending, (state) => {
                state.detailList.loading = true;
            })
            .addCase(getMortalityDetailList.fulfilled, (state, action) => {
                state.detailList.loading = false;
                state.detailList.data = action.payload || initialState.detailList.data;
            })
            .addCase(getMortalityDetailList.rejected, (state) => {
                state.detailList.loading = false;
                state.detailList.data = initialState.detailList.data;
            })

            .addCase(getMortalityDetail.pending, (state) => {
                state.detail.loading = true;
            })
            .addCase(getMortalityDetail.fulfilled, (state, action) => {
                state.detail.loading = false;
                state.detail.data = action.payload || initialState.detail.data;
            })
            .addCase(getMortalityDetail.rejected, (state) => {
                state.detail.loading = false;
                state.detail.data = initialState.detail.data;
            })

            .addCase(updateFishMortality.pending, (state) => {
                state.list.loading = true;
            })
            .addCase(updateFishMortality.fulfilled, (state, action) => {
                state.list.loading = false;
                const updatedFishMortality = action.payload;
                state.list.data = state.list.data.filter((fishMortality) => {
                    if (fishMortality.id === updatedFishMortality.id) {
                        Object.assign(fishMortality, {
                            siteId: updatedFishMortality.siteId,
                            site: updatedFishMortality.site,
                            penId: updatedFishMortality.penId,
                            pen: updatedFishMortality.pen
                        });

                        fishMortality.fishes = fishMortality.fishes.filter((fish) => {
                            const updatedFish = updatedFishMortality.fishes.find(
                                (f) => f.deadFishDetectionId === fish.deadFishDetectionId
                            );
                            fish.count = updatedFish?.count ? updatedFish.count : fish.count;
                            fish.fishName = updatedFish?.fishName
                                ? updatedFish.fishName
                                : fish.fishName;
                            return fish;
                        });
                    }
                    return fishMortality;
                });
            })
            .addCase(updateFishMortality.rejected, (state) => {
                state.list.loading = false;
            });
    }
});
