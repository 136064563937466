import { appInsights } from 'AppInsights';
import { Button, Col, Row } from 'antd';
import {
    BUTTON_GRAY_SELECTED,
    BUTTON_GRAY_UN_SELECTED,
    COUNT_RANGE_SELECT,
    DEAD_FISH_COUNTING_REPORT_NAME,
    EXPORT_ALL,
    EXPORT_BY_FILTER,
    FISH_GROUP_API,
    PER_COUNT_REPORT,
    PER_DAY_REPORT,
    TABLE_COLUMNS
} from 'common/constants';
import { FDDropdownDownload, FDSelection, FDTable } from 'components';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { setFromDead, setReportType, setToDead } from 'redux/slices/filterSlice';
import { useAppDispatch } from 'redux/store';
import { getReportTableByFishGroupId } from 'redux/thunks';

const ReportTable = ({ fishGroupDetail }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const COLUMNS_FOR_PER_COUNT_REPORT = [
        TABLE_COLUMNS.SITE,
        TABLE_COLUMNS.PEN,
        TABLE_COLUMNS.START_TIME,
        TABLE_COLUMNS.STOP_TIME,
        TABLE_COLUMNS.LAKS,
        TABLE_COLUMNS.ØRRET,
        TABLE_COLUMNS.BERGGYLT,
        TABLE_COLUMNS.ROGNKJEKS
    ];

    const COLUMNS_FOR_PER_DAY_REPORT = [
        TABLE_COLUMNS.DATE,
        TABLE_COLUMNS.SITE,
        TABLE_COLUMNS.PEN,
        TABLE_COLUMNS.LAKS,
        TABLE_COLUMNS.ØRRET,
        TABLE_COLUMNS.BERGGYLT,
        TABLE_COLUMNS.ROGNKJEKS
    ];

    const initialFilters = useSelector((s) => s.filter.data);

    const { data, loading } = useSelector((s) => s.fishGroupReportTable);

    const [columns, setColumns] = useState(COLUMNS_FOR_PER_COUNT_REPORT);

    const [downloadOptions, setDownloadOptions] = useState([]);

    useEffect(() => {
        const newFilters = {
            fromDead: initialFilters.fromDead,
            toDead: initialFilters.toDead,
            reportType: initialFilters.reportType
        };

        dispatch(
            getReportTableByFishGroupId({
                id: fishGroupDetail.id,
                filters: newFilters
            })
        );

        setDownloadOptions([
            {
                label: t('mortalityAnalysis.reportTable.download.byCurrentFilter'),
                params: { ...newFilters, exportType: EXPORT_BY_FILTER },
                className: 'download-current-filter-data-button'
            },
            {
                label: t('mortalityAnalysis.reportTable.download.allData'),
                params: { ...newFilters, exportType: EXPORT_ALL },
                className: 'download-all-data-button'
            }
        ]);
    }, [
        fishGroupDetail,
        initialFilters.fromDead,
        initialFilters.toDead,
        initialFilters.reportType
    ]);

    const handleReportTypeChange = (newType) => {
        if (newType === PER_COUNT_REPORT) {
            setColumns(COLUMNS_FOR_PER_COUNT_REPORT);
        } else if (newType === PER_DAY_REPORT) {
            setColumns(COLUMNS_FOR_PER_DAY_REPORT);
        }

        dispatch(setReportType(newType));

        appInsights.trackEvent({
            name: 'FFI Report table type change',
            properties: { type: newType }
        });
    };

    const handleDeadChange = (value, object) => {
        dispatch(setFromDead(object?.from ?? null));
        dispatch(setToDead(object?.to ?? null));

        appInsights.trackEvent({
            name: 'FFI Dead fish count range change',
            properties: { from: object?.from, to: object?.to }
        });
    };

    const convertColumns = (columns) => {
        if (data[0]?.fishes?.length) {
            columns = columns.filter(
                (col) =>
                    data[0].fishes.some((fish) => fish.fishId === col.dataIndex) || col.isDefault
            );
        }

        return columns;
    };

    const convertReportData = (reports) => {
        return reports.map((item, index) => {
            let obj = Object.assign(
                {},
                item,
                item.fishes.reduce((accumulator, currentValue) => {
                    return {
                        key: index,
                        ...accumulator,
                        [currentValue.fishId]: currentValue.dead
                    };
                }, {})
            );

            delete obj.fishes;

            return obj;
        });
    };

    return (
        <div className="mt-5">
            <h2 className="mb-2">{t('mortalityAnalysis.reportTable.title')}</h2>

            <Row className="inline flex justify-between w-full mb-1">
                <Col
                    className="inline mr-4 flex xs:justify-center xl:justify-start py-1"
                    xs={24}
                    xl={8}
                >
                    <Button
                        disabled={loading}
                        className={
                            initialFilters.reportType === PER_COUNT_REPORT
                                ? BUTTON_GRAY_SELECTED
                                : BUTTON_GRAY_UN_SELECTED
                        }
                        onClick={() => handleReportTypeChange(PER_COUNT_REPORT)}
                    >
                        {t('mortalityAnalysis.reportTable.perCount')}
                    </Button>
                    &nbsp;
                    <Button
                        disabled={loading}
                        className={
                            initialFilters?.reportType === PER_DAY_REPORT
                                ? BUTTON_GRAY_SELECTED
                                : BUTTON_GRAY_UN_SELECTED
                        }
                        onClick={() => handleReportTypeChange(PER_DAY_REPORT)}
                    >
                        {t('mortalityAnalysis.reportTable.perDay')}
                    </Button>
                </Col>

                <Col className="flex xs:justify-between xl:justify-end py-1" xs={24} xl={15}>
                    <div className="xs:flex-col sm:flex-row mortality-count-filter">
                        <span className="font-semibold title text-base text-gray-700">
                            {t('mortalityAnalysis.reportTable.mortalityCount.title')}{' '}
                        </span>
                        <FDSelection
                            placeholder={t(
                                'mortalityAnalysis.reportTable.mortalityCount.placeholder'
                            )}
                            className="w-52 select xl:mr-10"
                            listSelectItem={COUNT_RANGE_SELECT.map((range) => ({
                                ...range,
                                text: t(range.text)
                            }))}
                            onChange={handleDeadChange}
                            allowClear={true}
                        />
                    </div>
                    <FDDropdownDownload
                        options={downloadOptions}
                        exportApi={`${FISH_GROUP_API}/${fishGroupDetail.id}/dead-fish-counting-export`}
                        fileName={DEAD_FISH_COUNTING_REPORT_NAME}
                    />
                </Col>
            </Row>

            <FDTable
                headers={convertColumns(columns)}
                data={convertReportData(data || [])}
                className="report-table"
                loading={loading}
            />
        </div>
    );
};

ReportTable.propTypes = {
    fishGroupDetail: PropTypes.object.isRequired
};

export default ReportTable;
