import { withPageViewTracking } from 'hoc';
import FilterBar from './FilterBar';
import './StressEvent.scss';
import StressEventDetail from './StressEventDetail';

const Event = () => {
    return (
        <>
            <FilterBar />

            <StressEventDetail />
        </>
    );
};

export default withPageViewTracking(Event, 'Event detail');
