import { appInsights } from 'AppInsights';
import { FDFilter } from 'components';
import { FiltersContext } from 'context/filtersContext';
import { withPageViewTracking } from 'hoc';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { NotFound } from 'pages';
import { useCallback, useEffect, useState } from 'react';
import { useAppDispatch } from 'redux/store';
import {
    getFishwellFishIdsSelection,
    getPensSelection,
    getServiceBoatsSelection,
    getSitesSelection,
    getStressAnalysisCorrelationTypes,
    getStressAnalysisForCrowdingTimePerThrow,
    getStressAnalysisForEvent,
    getStressAnalysisForFish,
    getStressAnalysisForMortalityPerEvent,
    getStressAnalysisForOxygenLevelPerThrow,
    getStressAnalysisForStressLevelPerThrow,
    getStressAnalysisForStressZonePerThrow
} from 'redux/thunks';
import AnalysisCharts from './AnalysisCharts';
import AnalysisInfo from './AnalysisInfo';
import CorrelationAnalysis from './CorrelationAnalysis';

const StressAnalysis = () => {
    const { showStressAnalysis } = useFlags();

    const dispatch = useAppDispatch();

    const [filters, setFilters] = useState({});

    useEffect(() => {
        dispatch(getPensSelection());
        dispatch(getSitesSelection());
        dispatch(getFishwellFishIdsSelection());
        dispatch(getServiceBoatsSelection());
        dispatch(getStressAnalysisCorrelationTypes());
    }, []);

    const handleFilterSubmit = useCallback((query) => {
        setFilters(query);

        dispatch(getStressAnalysisForEvent(query));
        dispatch(getStressAnalysisForFish(query));
        dispatch(getStressAnalysisForStressLevelPerThrow(query));
        dispatch(getStressAnalysisForCrowdingTimePerThrow(query));
        dispatch(getStressAnalysisForStressZonePerThrow(query));
        dispatch(getStressAnalysisForOxygenLevelPerThrow(query));
        dispatch(getStressAnalysisForMortalityPerEvent(query));

        appInsights.trackEvent({ name: 'Stress analysis filter apply' });
    }, []);

    if (!showStressAnalysis) return <NotFound />;

    return (
        <>
            <FDFilter
                hasSite
                hasPen
                hasFishGroupIds
                hasServiceBoat
                hasDateRange
                hasStressLevelStatus
                callback={handleFilterSubmit}
            />

            <AnalysisInfo />

            <AnalysisCharts />

            <FiltersContext.Provider value={filters}>
                <CorrelationAnalysis />
            </FiltersContext.Provider>
        </>
    );
};

export default withPageViewTracking(StressAnalysis, 'Stress analysis');
