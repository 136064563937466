import { appInsights } from 'AppInsights';
import { Button, Col, Row } from 'antd';
import {
    BUTTON_AS_LINK,
    BUTTON_GRAY_SELECTED,
    BUTTON_GRAY_UN_SELECTED,
    COUNT_RANGE_SELECT,
    DEAD_FISH_COUNTING_REPORT_NAME,
    EXPORT_ALL,
    EXPORT_BY_FILTER,
    EXPORT_DEAD_FISH_COUNTING_REPORT_API,
    FISH_ATTRIBUTES_LAKS,
    FISH_DIAGNOSTIC_ATTRIBUTES_LAKS,
    FISH_GROUP_DETAIL_PAGE,
    PER_COUNT_REPORT,
    PER_DAY_REPORT,
    TABLE_COLUMNS
} from 'common/constants';
import { formatNumber } from 'common/utils';
import {
    FDDropdownDownload,
    FDSelection,
    FDTable,
    ViewMoreAttachments,
    WoundAndSizeAttachment
} from 'components';
import { FiltersContext } from 'context/filtersContext';
import { useFlags } from 'launchdarkly-react-client-sdk';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
    setFromDead,
    setReportType,
    setSelectedFishGroupId,
    setToDead
} from 'redux/slices/filterSlice';
import { useAppDispatch } from 'redux/store';
import { getReportTable } from 'redux/thunks';

const ReportTable = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { expandRowMortalityReport } = useFlags();

    const filters = useContext(FiltersContext);

    const { fromDead, toDead, reportType } = useSelector((s) => s.filter.data);
    const { data, loading } = useSelector((s) => s.reportTable);

    const FISH_GROUPS_COLUMN = {
        title: 'general.table.fishGroups',
        dataIndex: 'fishGroups',
        className: 'table-report-fish-groups',
        render: (fishGroups) =>
            fishGroups.map((fishGroup) => (
                <button
                    className={`mr-4 inline-block ${BUTTON_AS_LINK}`}
                    key={fishGroup.id}
                    onClick={() => handleFishGroupClick(fishGroup)}
                >
                    {fishGroup.fishGroupId}-{fishGroup.sibling}
                </button>
            )),
        isDefault: true
    };

    const COLUMNS_FOR_PER_COUNT_REPORT = [
        TABLE_COLUMNS.SITE,
        TABLE_COLUMNS.PEN,
        FISH_GROUPS_COLUMN,
        TABLE_COLUMNS.START_TIME,
        TABLE_COLUMNS.STOP_TIME,
        TABLE_COLUMNS.LAKS,
        TABLE_COLUMNS.ØRRET,
        TABLE_COLUMNS.BERGGYLT,
        TABLE_COLUMNS.ROGNKJEKS
    ];

    const COLUMNS_FOR_PER_DAY_REPORT = [
        TABLE_COLUMNS.DATE,
        TABLE_COLUMNS.SITE,
        TABLE_COLUMNS.PEN,
        FISH_GROUPS_COLUMN,
        TABLE_COLUMNS.LAKS,
        TABLE_COLUMNS.ØRRET,
        TABLE_COLUMNS.BERGGYLT,
        TABLE_COLUMNS.ROGNKJEKS
    ];

    const [columns, setColumns] = useState(COLUMNS_FOR_PER_COUNT_REPORT);
    const [modalState, setModalState] = useState({ open: false, attachment: {} });

    const [downloadOptions, setDownloadOptions] = useState([]);

    useEffect(() => {
        if (isEmpty(filters)) return;

        const newFilters = {
            ...filters,
            fromDead: fromDead,
            toDead: toDead,
            reportType: reportType
        };

        dispatch(getReportTable(newFilters));

        setDownloadOptions([
            {
                label: t('mortalityAnalysis.reportTable.download.byCurrentFilter'),
                params: { ...newFilters, exportType: EXPORT_BY_FILTER },
                className: 'download-current-filter-data-button'
            },
            {
                label: t('mortalityAnalysis.reportTable.download.allData'),
                params: { ...newFilters, exportType: EXPORT_ALL },
                className: 'download-all-data-button'
            }
        ]);
    }, [filters, fromDead, toDead, reportType]);

    const handleFishGroupClick = (fishGroup) => {
        dispatch(setSelectedFishGroupId(fishGroup.id));

        navigate(FISH_GROUP_DETAIL_PAGE);

        appInsights.trackEvent({
            name: 'Fish group button click',
            properties: { rootLTreePath: fishGroup.rootLTreePath }
        });
    };

    const handleReportTypeChange = (newType) => {
        if (newType === PER_COUNT_REPORT) {
            setColumns(COLUMNS_FOR_PER_COUNT_REPORT);
        } else {
            setColumns(COLUMNS_FOR_PER_DAY_REPORT);
        }

        dispatch(setReportType(newType));

        appInsights.trackEvent({
            name: 'Report table type change',
            properties: { type: newType }
        });
    };

    const handleDeadChange = (value, object) => {
        dispatch(setFromDead(object?.from ?? null));
        dispatch(setToDead(object?.to ?? null));

        appInsights.trackEvent({
            name: 'Dead fish count range change',
            properties: { from: object?.from, to: object?.to }
        });
    };

    const convertColumns = (columns) => {
        if (filters.fishNames?.length) {
            columns = columns.filter(
                (col) => filters.fishNames.includes(col.dataIndex) || col.isDefault
            );
        }

        return columns;
    };

    const convertReportData = (reports) =>
        reports.map((item, index) => {
            let obj = Object.assign(
                {},
                item,
                item.fishes.reduce(
                    (accumulator, currentValue) => ({
                        key: index,
                        ...accumulator,
                        [currentValue.fishId]: currentValue.dead,
                        [`fishAttributes_${currentValue.fishId}`]: currentValue.fishAttributes,
                        [`fishDiagnosticAttributes_${currentValue.fishId}`]:
                            currentValue.fishDiagnosticAttributes?.map((fda) => ({
                                name: fda.name,
                                count: fda.value
                            }))
                    }),
                    {}
                )
            );

            delete obj.fishes;

            return obj;
        });

    const expandedRowRender = (record) => {
        const resultFishAttribute = get(record, FISH_ATTRIBUTES_LAKS, []).reduce((acc, obj) => {
            acc[obj.name] = obj.count;
            acc[`${obj.name}_attachments`] = obj.attachments;
            return acc;
        }, {});
        const resultFishDiagnosticAttribute = get(
            record,
            FISH_DIAGNOSTIC_ATTRIBUTES_LAKS,
            []
        ).reduce((acc, obj) => {
            acc[obj.name] = obj.count;
            return acc;
        }, {});

        return (
            <>
                <div className="py-2 ml-10">
                    <Row className="w-full flex items-start xs:gap-x-6 xl:gap-x-0">
                        <Col xl={2} xxl={1}>
                            <span className="font-medium underline">
                                {t('dailyReport.report.table.overall')}:
                            </span>
                        </Col>
                        <Col xl={4} xxl={3}>
                            <span className="mr-1">
                                {t('general.fishDiagnosticAttribute.numberOfAnalysis')}:
                            </span>
                            <span> {resultFishDiagnosticAttribute.number_of_analyze}</span>
                        </Col>
                        <Col xl={4} xxl={3}>
                            <span className="mr-1">
                                {t('general.fishDiagnosticAttribute.averageLength')}:
                            </span>
                            <span>
                                {resultFishDiagnosticAttribute.average_length != null &&
                                    `${formatNumber(
                                        resultFishDiagnosticAttribute.average_length
                                    )}cm`}
                            </span>
                        </Col>
                        <Col xl={4} xxl={3}>
                            <span className="mr-1">
                                {t('general.fishDiagnosticAttribute.sizeOutlier')}:
                            </span>
                            <span>
                                {resultFishDiagnosticAttribute.size_outlier != null &&
                                    `${formatNumber(resultFishDiagnosticAttribute.size_outlier)}%`}
                            </span>
                        </Col>
                        <Col xl={6} xxl={4}>
                            <span className="mr-1">
                                {t('general.fishDiagnosticAttribute.woundPercentage')}:
                            </span>
                            <span>
                                {resultFishDiagnosticAttribute.wound_percentage != null &&
                                    `${formatNumber(
                                        resultFishDiagnosticAttribute.wound_percentage
                                    )}%`}
                            </span>
                        </Col>
                    </Row>
                </div>

                <div className="py-2 ml-10">
                    <Row className="w-full flex items-start xs:gap-x-6 xl:gap-x-0">
                        <Col xl={2} xxl={1}>
                            <span className="font-medium underline">
                                {t('general.fishAttribute.wound')}:
                            </span>
                        </Col>
                        <Col xl={4} xxl={3}>
                            <span className="mr-1">{t('dailyReport.report.table.total')}:</span>
                            <span> {resultFishAttribute.wound}</span>
                            <WoundAndSizeAttachment
                                attachments={resultFishAttribute.wound_attachments}
                            />
                        </Col>
                        <Col xl={4} xxl={3}>
                            <span className="mr-1">{t('general.fishAttribute.woundOnGill')}:</span>
                            <span>{resultFishAttribute.wound_on_gill}</span>
                            <WoundAndSizeAttachment
                                attachments={resultFishAttribute.wound_on_gill_attachments}
                            />
                        </Col>
                        <Col xl={4} xxl={3}>
                            <span className="mr-1">{t('general.fishAttribute.woundOnMouth')}:</span>
                            <span> {resultFishAttribute.wound_on_mouth}</span>
                            <WoundAndSizeAttachment
                                attachments={resultFishAttribute.wound_on_mouth_attachments}
                            />
                        </Col>
                        <Col xl={4} xxl={3}>
                            <span className="mr-1">{t('general.fishAttribute.woundOnBack')}:</span>
                            <span>{resultFishAttribute.wound_on_back}</span>
                            <WoundAndSizeAttachment
                                attachments={resultFishAttribute.wound_on_back_attachments}
                            />
                        </Col>
                        <Col xl={6} xxl={3}>
                            <span className="mr-1">{t('general.fishAttribute.woundOnBelly')}:</span>
                            <span>{resultFishAttribute.wound_on_belly}</span>
                            <WoundAndSizeAttachment
                                attachments={resultFishAttribute.wound_on_belly_attachments}
                            />
                        </Col>
                        <Col xs={0} xl={2} xxl={0}></Col> {/* Responsive purpose only */}
                        <Col xl={4} xxl={3}>
                            <span className="mr-1">{t('general.fishAttribute.woundOnTail')}:</span>
                            <span>{resultFishAttribute.wound_on_tail}</span>
                            <WoundAndSizeAttachment
                                attachments={resultFishAttribute.wound_on_tail_attachments}
                            />
                        </Col>
                    </Row>
                </div>

                <div className="py-2 ml-10">
                    <Row className="w-full flex items-start xs:gap-x-6 xl:gap-x-0">
                        <Col xl={2} xxl={1}>
                            <span className="font-medium underline">
                                {t('dailyReport.report.table.others')}:
                            </span>
                        </Col>
                        <Col xl={4} xxl={3}>
                            <span className="mr-1">{t('general.fishAttribute.fillet')}:</span>
                            <span> {resultFishAttribute.fillet}</span>
                            <WoundAndSizeAttachment
                                attachments={resultFishAttribute.fillet_attachments}
                            />
                        </Col>
                        <Col xl={4} xxl={3}>
                            <span className="mr-1">{t('general.fishAttribute.looserFish')}:</span>
                            <span>{resultFishAttribute.looser_fish}</span>
                            <WoundAndSizeAttachment
                                attachments={resultFishAttribute.looser_fish_attachments}
                            />
                        </Col>
                        <Col xl={4} xxl={3}>
                            <span className="mr-1">{t('general.fishAttribute.noWound')}:</span>
                            <span>{resultFishAttribute.no_wound}</span>
                            <WoundAndSizeAttachment
                                attachments={resultFishAttribute.no_wound_attachments}
                            />
                        </Col>
                    </Row>
                </div>

                <div className="flex justify-end">
                    <Button
                        onClick={() => {
                            setModalState({ open: true, attachment: resultFishAttribute });
                        }}
                    >
                        {t('dailyReport.report.table.viewMoreImages')}
                    </Button>
                </div>
            </>
        );
    };

    return (
        <div className="mt-5">
            <h2 className="mb-2">{t('mortalityAnalysis.reportTable.title')}</h2>
            <Row className="flex justify-between w-full mb-1">
                <Col className="mr-4 flex xs:justify-center xl:justify-start py-1" xs={24} xl={8}>
                    <Button
                        disabled={loading}
                        className={
                            reportType === PER_COUNT_REPORT
                                ? BUTTON_GRAY_SELECTED
                                : BUTTON_GRAY_UN_SELECTED
                        }
                        onClick={() => handleReportTypeChange(PER_COUNT_REPORT)}
                    >
                        {t('mortalityAnalysis.reportTable.perCount')}
                    </Button>
                    &nbsp;
                    <Button
                        id="per-day"
                        disabled={loading}
                        className={
                            reportType === PER_DAY_REPORT
                                ? BUTTON_GRAY_SELECTED
                                : BUTTON_GRAY_UN_SELECTED
                        }
                        onClick={() => handleReportTypeChange(PER_DAY_REPORT)}
                    >
                        {t('mortalityAnalysis.reportTable.perDay')}
                    </Button>
                </Col>

                <Col className="flex xs:justify-between xl:justify-end py-1" xs={24} xl={15}>
                    <div className="xs:flex-col sm:flex-row mortality-count-filter">
                        <span className="font-semibold title text-base text-gray-700">
                            {t('mortalityAnalysis.reportTable.mortalityCount.title')}{' '}
                        </span>
                        <FDSelection
                            placeholder={t(
                                'mortalityAnalysis.reportTable.mortalityCount.placeholder'
                            )}
                            className="w-52 select xl:mr-10 mortality-count"
                            listSelectItem={COUNT_RANGE_SELECT.map((range) => ({
                                ...range,
                                text: t(range.text)
                            }))}
                            onChange={handleDeadChange}
                            allowClear={true}
                        />
                    </div>
                    <FDDropdownDownload
                        options={downloadOptions}
                        exportApi={EXPORT_DEAD_FISH_COUNTING_REPORT_API}
                        fileName={DEAD_FISH_COUNTING_REPORT_NAME}
                    />
                </Col>
            </Row>

            <FDTable
                headers={convertColumns(columns)}
                data={convertReportData(data || [])}
                className="report-table"
                loading={loading}
                showExpandColumn={expandRowMortalityReport ? true : false}
                expandable={{ expandedRowRender }}
            />

            <ViewMoreAttachments
                open={modalState.open}
                attachment={modalState.attachment}
                onClose={() => setModalState({ open: false, attachment: {} })}
            />
        </div>
    );
};

export default ReportTable;
