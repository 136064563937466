import { Card, Spin } from 'antd';
import { appInsights } from 'AppInsights';
import { BLUE, DARK_GREEN, FLOW_PAGE, GREEN, ORANGE } from 'common/constants';
import { FDColumn } from 'components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { processFlowDetectionSelector } from 'redux/selector';

const Flow = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { data: flow, loading } = useSelector(processFlowDetectionSelector);

    var flowDetections = flow.fishes?.map((fish) => ({
        name: t(`general.fishMortality.${fish.fishName}`),
        type: t(`general.fishMortality.${fish.fishName}`),
        value: fish.count
    }));

    const handleFlowChartClick = () => {
        if (!flow.fishes) return;

        navigate(FLOW_PAGE);

        appInsights.trackEvent({
            name: 'Flow is navigated from process page'
        });
    };

    return (
        <Spin spinning={loading}>
            <Card
                onClick={() => handleFlowChartClick()}
                className={`mt-2 ${flow.fishes && 'cursor-pointer'}`}
                role="button"
            >
                <p className="font-medium text-base">{t('process.flow')}</p>
                <FDColumn
                    data={flowDetections}
                    color={[ORANGE, GREEN, DARK_GREEN, BLUE]}
                    height={300}
                />
            </Card>
        </Spin>
    );
};

export default Flow;
