import { TABLE_COLUMNS } from 'common/constants';
import { FDTable } from 'components';
import { FiltersContext } from 'context/filtersContext';
import isEmpty from 'lodash/isEmpty';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const ReportTable = () => {
    const { t } = useTranslation();

    const filters = useContext(FiltersContext);

    const { data: flowReports, loading: flowReportsLoading } = useSelector((s) => s.flow);

    const defaultColumns = [
        TABLE_COLUMNS.SITE,
        TABLE_COLUMNS.PEN,
        TABLE_COLUMNS.START_TIME,
        TABLE_COLUMNS.STOP_TIME,
        TABLE_COLUMNS.LAKS,
        TABLE_COLUMNS.ØRRET,
        TABLE_COLUMNS.BERGGYLT,
        TABLE_COLUMNS.ROGNKJEKS
    ];

    const convertedColumns = isEmpty(filters.fishNames)
        ? defaultColumns
        : defaultColumns.filter(
              (col) => filters.fishNames.includes(col.dataIndex) || col.isDefault
          );

    const formattedFlowReports = flowReports.map((item, index) => {
        let obj = Object.assign(
            {},
            item,
            item.fishes.reduce((accumulator, currentValue) => {
                return {
                    key: index,
                    ...accumulator,
                    [currentValue.fishName]: currentValue.count
                };
            }, {})
        );

        delete obj.fishes;

        return obj;
    });

    return (
        <div className="mt-5">
            <h2 className="mb-2">{t('flow.report')}</h2>
            <FDTable
                headers={convertedColumns}
                data={formattedFlowReports}
                loading={flowReportsLoading}
            ></FDTable>
        </div>
    );
};

export default ReportTable;
