import { appInsights } from 'AppInsights';
import { Card, Col, Divider, Row } from 'antd';
import { BUTTON_AS_LINK, STRESS_EVENT_PAGE } from 'common/constants';
import { formatNumber } from 'common/utils';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setFromDate, setSelectedStressEventId, setToDate } from 'redux/slices/filterSlice';
import { useAppDispatch } from 'redux/store';
import { getFishHealthByFishGroupId } from 'redux/thunks';

const HealthConditionCard = ({ fishGroupDetail }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { data: fishGroupFishHealth, loading } = useSelector((s) => s.fishGroupFishHealth);

    const { treatment } = fishGroupFishHealth;

    useEffect(() => {
        dispatch(getFishHealthByFishGroupId(fishGroupDetail.id));
    }, [fishGroupDetail]);

    const handleLastEventClick = () => {
        if (!treatment?.lastEventId) return;

        dispatch(setSelectedStressEventId(treatment.lastEventId));

        if (fishGroupDetail?.actionDate) dispatch(setFromDate(moment(fishGroupDetail.actionDate)));

        if (fishGroupDetail?.inActiveDate)
            dispatch(setToDate(moment(fishGroupDetail.inActiveDate)));

        navigate(STRESS_EVENT_PAGE);

        appInsights.trackEvent({
            name: 'FFI health condition card last event click',
            properties: { eventId: treatment.lastEventId }
        });
    };

    return (
        <div className="mt-5">
            <h2 className="mb-2">{t('eventDetail.fishHealthCondition.title')}</h2>

            <Card className="text-base" loading={loading}>
                <Divider orientation="center">
                    <div>{t('eventDetail.fishHealthCondition.treatment')}</div>
                </Divider>

                <Row gutter={24}>
                    <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6} className="py-4">
                        <div className="font-semibold">
                            {t('eventDetail.fishHealthCondition.daysSinceLast')}
                        </div>

                        <div>
                            {!treatment?.lastEventDays
                                ? t('eventDetail.fishHealthCondition.noEvent')
                                : formatNumber(treatment?.lastEventDays)}
                        </div>
                    </Col>

                    <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6} className="py-4">
                        <div className="font-semibold">
                            {t('eventDetail.fishHealthCondition.lastTreatment')}
                        </div>

                        <div>
                            {treatment?.lastEventId ? (
                                <button className={BUTTON_AS_LINK} onClick={handleLastEventClick}>
                                    {t('eventDetail.fishHealthCondition.visit')}
                                </button>
                            ) : (
                                <div>{t('eventDetail.fishHealthCondition.noEvent')}</div>
                            )}
                        </div>
                    </Col>

                    <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6} className="py-4">
                        <div className="font-semibold">
                            {t('eventDetail.fishHealthCondition.averageDaysBetween')}
                        </div>

                        <div>{formatNumber(treatment?.avgEventDaysOnFishGroup)}</div>
                    </Col>

                    <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6} className="py-4">
                        <div className="font-semibold">
                            {t('eventDetail.fishHealthCondition.numberOfTreatments')}
                        </div>

                        <div>{formatNumber(treatment?.totalEventOnFishGroup)}</div>
                    </Col>
                </Row>
            </Card>
        </div>
    );
};

HealthConditionCard.propTypes = {
    fishGroupDetail: PropTypes.object.isRequired
};

export default HealthConditionCard;
