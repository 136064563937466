import { Spin } from 'antd';
import { appInsights } from 'AppInsights';
import { DAILY_REPORT_PAGE } from 'common/constants';
import { formatNumber } from 'common/utils';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
    setDailyDate,
    setFishNames,
    setSelectedPens,
    setSelectedSites
} from 'redux/slices/filterSlice';
import { useAppDispatch } from 'redux/store';
import './FDDailyMortalityCount.scss';

const FDDailyMortalityCount = ({ siteId, penId }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { data: count, loading } = useSelector((s) => s.dailyReportCounting);

    const handleDailyCountClick = () => {
        dispatch(setDailyDate(moment()));
        dispatch(setSelectedSites([siteId]));
        dispatch(setSelectedPens([penId]));
        dispatch(setFishNames([]));

        navigate(DAILY_REPORT_PAGE);

        appInsights.trackEvent({ name: 'Daily mortality count click' });
    };

    return (
        <div className="flex flex-col justify-center xs:mt-8 sm:mt-0">
            <span className="font-semibold text-base mb-1">
                {t('component.mortalityCount.mortalityCountToday')}
            </span>
            <div
                className="daily-button"
                role="button"
                tabIndex="0"
                onClick={handleDailyCountClick}
            >
                <div className="mortality text-center">
                    <div className="text-white text-3xl font-semibold">
                        {loading ? <Spin /> : formatNumber(count)}
                    </div>
                    <div className="text-sm text-slate-300">
                        {t('component.mortalityCount.viewTodayReport')}
                    </div>
                </div>
            </div>
        </div>
    );
};

FDDailyMortalityCount.propTypes = {
    siteId: PropTypes.string,
    penId: PropTypes.string,
    className: PropTypes.string
};

export default FDDailyMortalityCount;
