import { withPageViewTracking } from 'hoc';
import FilterBar from './FilterBar';
import MortalityDetail from './MortalityDetail';

const Mortality = () => {
    return (
        <>
            <FilterBar />

            <MortalityDetail />
        </>
    );
};

export default withPageViewTracking(Mortality, 'Mortality detail');
