import FilterBar from './FilterBar';
import { withPageViewTracking } from 'hoc';
import ProcessDetail from './ProcessDetail';

const Process = () => {
    return (
        <>
            <FilterBar />
            <ProcessDetail />
        </>
    );
};

export default withPageViewTracking(Process, 'Process detail');
