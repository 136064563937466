import { ReloadOutlined, SendOutlined } from '@ant-design/icons';
import { Avatar, Button, Col, Comment, Drawer, Form, Row, Spin, Tooltip } from 'antd';
import { appInsights } from 'AppInsights';
import defaultAvatar from 'assets/Default_Avatar.png';
import { BUTTON_BLUE_CLASS, EVENT_NOTE_METHOD, TextArea } from 'common/constants';
import { alertErrorMessage, formatDateTime, getTimeAgo } from 'common/utils';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import { addEventNote, getEventNotesByEventId, pushEventNote } from 'redux/thunks';
import { SIGNALR_SERVICE } from 'services';
import './EventNotes.scss';

const EventNotes = ({
    isOpenEventNotes = false,
    toggleEventNotesCollapsible = () => {},
    setHasNewEventNote = () => {}
}) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const [form] = Form.useForm();
    const noteContentRef = useRef(null);

    const { selectedStressEventId } = useSelector((s) => s.filter.data);
    const { data: event } = useSelector((s) => s.eventDetail);
    const { data: eventNotes, loading, isWaitingEventNote } = useSelector((s) => s.eventNote);
    const { data: currentProfile } = useSelector((s) => s.profile);

    useEffect(() => {
        if (selectedStressEventId) {
            dispatch(getEventNotesByEventId(selectedStressEventId));
        }
    }, [selectedStressEventId]);

    useEffect(() => {
        const connection = SIGNALR_SERVICE.getSignalRConnection('event-note-hubs');

        if (connection) {
            connection
                .start()
                .then(() => {
                    connection.on(EVENT_NOTE_METHOD.SEND_EVENT_NOTE, (eventNote) => {
                        if (eventNote.eventId === event.id) {
                            const currentUserID = get(currentProfile, 'id', '');
                            const userID = get(eventNote, 'user.id', '');
                            const isOwner = userID === currentUserID;

                            if (isOwner) {
                                eventNote = { ...eventNote, isOwner: true };
                            }

                            dispatch(pushEventNote(eventNote)).then(() => {
                                if (isOwner) {
                                    noteContentRef?.current?.scrollIntoView({
                                        behavior: 'smooth',
                                        block: 'end'
                                    });
                                } else {
                                    setHasNewEventNote(true);
                                }
                            });
                        }
                    });
                })
                .catch((err) => {
                    console.log('Connect to event note hub failed: ', err);
                });
        }

        return () => {
            connection?.stop();
        };
    }, [event.id]);

    const handleEventNoteSend = async () => {
        const noteText = form.getFieldValue('noteText');
        if (!noteText) return;

        var req = {
            eventId: event.id,
            noteText: noteText
        };
        form.resetFields();
        dispatch(addEventNote({ id: event.id, item: req })).catch((errors) => {
            alertErrorMessage(errors);
        });

        appInsights.trackEvent({ name: 'Event note send', properties: { noteText } });
    };

    const handleEventNoteReload = async () => {
        await dispatch(getEventNotesByEventId(event.id)).unwrap();

        noteContentRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
    };

    return (
        <Drawer
            afterOpenChange={() => {
                setHasNewEventNote(false);
                if (noteContentRef?.current) {
                    noteContentRef?.current?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'end'
                    });
                }
            }}
            title={t('eventDetail.eventNote.title')}
            placement="right"
            onClose={toggleEventNotesCollapsible}
            open={isOpenEventNotes}
            bodyStyle={{ padding: '1.2em' }}
            extra={
                <Tooltip placement="bottomLeft" title={t('eventDetail.eventNote.reload')}>
                    <Button
                        onClick={() => handleEventNoteReload()}
                        className={`${BUTTON_BLUE_CLASS} rounded-full w-6 h-6 flex justify-center align-items-center p-2`}
                        icon={<ReloadOutlined />}
                    />
                </Tooltip>
            }
        >
            <div className="event-notes flex flex-col justify-between w-full h-full">
                <Row gutter={8} className="scrollable-item">
                    <Col xs={24} style={{ padding: 0 }} ref={noteContentRef}>
                        <Spin spinning={loading} style={{ top: 'unset', bottom: '0' }}>
                            {!(eventNotes.length || loading) ? (
                                <p className="m-0 text-center">
                                    {t('eventDetail.eventNote.noEventNotesFound')}
                                </p>
                            ) : (
                                <div>
                                    {eventNotes.map((eventNote) => (
                                        <Comment
                                            key={eventNote.id}
                                            className={`${
                                                eventNote.isOwner
                                                    ? 'rounded-lg justify-items-start flex'
                                                    : 'text-gray-600'
                                            }`}
                                            style={
                                                eventNote.isOwner
                                                    ? { direction: 'rtl' }
                                                    : { paddingRight: '12' }
                                            }
                                            author={eventNote.user?.name}
                                            avatar={
                                                <Avatar
                                                    className={`shadow-lg mt-1 ${
                                                        eventNote.isOwner ? 'ml-2' : 'mr-2'
                                                    }`}
                                                    src={eventNote.user?.avatar || defaultAvatar}
                                                    alt={eventNote.user?.name}
                                                />
                                            }
                                            content={
                                                <Tooltip
                                                    placement="topRight"
                                                    title={formatDateTime(eventNote.noteTime)}
                                                >
                                                    <p
                                                        className={`content p-2 font-semibold ${
                                                            eventNote.isOwner
                                                                ? `w-full bg-sky-700 text-white`
                                                                : 'text-gray-600'
                                                        }`}
                                                        style={
                                                            eventNote.isOwner
                                                                ? {
                                                                      direction: 'ltr',
                                                                      height: 'fit-content'
                                                                  }
                                                                : {}
                                                        }
                                                    >
                                                        {eventNote.noteText}
                                                    </p>
                                                </Tooltip>
                                            }
                                            datetime={
                                                <span
                                                    className={`${
                                                        eventNote.isOwner
                                                            ? 'flex text-gray-600'
                                                            : 'text-gray-600'
                                                    }`}
                                                    style={
                                                        eventNote.isOwner
                                                            ? { direction: 'ltr' }
                                                            : {}
                                                    }
                                                >
                                                    {getTimeAgo(eventNote.noteTime)}
                                                </span>
                                            }
                                        />
                                    ))}
                                </div>
                            )}
                        </Spin>
                    </Col>
                </Row>
                <Row gutter={8} className="flex pt-2 w-full">
                    <Form
                        className="w-full flex justify-between align-middle"
                        name="basic"
                        onFinish={handleEventNoteSend}
                        autoComplete="off"
                        form={form}
                        layout="inline"
                    >
                        <Form.Item
                            name="noteText"
                            className=""
                            style={{ width: '75%', marginRight: '5px' }}
                        >
                            <TextArea
                                className="w-full"
                                name="noteText"
                                autoSize={{ minRows: 1, maxRows: 6 }}
                                placeholder={t('eventDetail.eventNote.placeholder')}
                            />
                        </Form.Item>
                        <Button
                            loading={isWaitingEventNote}
                            type="submit"
                            icon={<SendOutlined />}
                            className={`${BUTTON_BLUE_CLASS}`}
                            onClick={handleEventNoteSend}
                        >
                            {t('eventDetail.eventNote.send')}
                        </Button>
                    </Form>
                </Row>
            </div>
        </Drawer>
    );
};

EventNotes.propTypes = {
    isOpenEventNotes: PropTypes.bool,
    toggleEventNotesCollapsible: PropTypes.func,
    setHasNewEventNote: PropTypes.func
};

export default EventNotes;
