import { Card, Col, Row, Spin } from 'antd';
import { CORRELATION_CATEGORY } from 'common/constants';
import { formatNumber, formatSeconds } from 'common/utils';
import { FDBullet, FDScatter, FDSelection } from 'components';
import { FiltersContext } from 'context/filtersContext';
import isEmpty from 'lodash/isEmpty';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import { getStressAnalysisForCorrelation, getStressAnalysisForRegression } from 'redux/thunks';

const CorrelationAnalysis = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const filters = useContext(FiltersContext);

    const { data: correlationTypes, loading: correlationTypesLoading } = useSelector(
        (s) => s.correlationAnalysis.correlationType
    );
    const { data: regressions, loading: regressionsLoading } = useSelector(
        (s) => s.correlationAnalysis.regression
    );
    const { data: correlation, loading: correlationLoading } = useSelector(
        (s) => s.correlationAnalysis.correlation
    );

    const [selectedCorrelationType, setSelectedCorrelationType] = useState();

    useEffect(() => {
        if (!isEmpty(correlationTypes)) {
            setSelectedCorrelationType(correlationTypes[0]);
        }
    }, [correlationTypes]);

    useEffect(() => {
        if (!selectedCorrelationType?.id || isEmpty(filters)) return;

        const newFilters = {
            ...filters,
            correlationTypeId: selectedCorrelationType.id
        };
        dispatch(getStressAnalysisForRegression(newFilters));
        dispatch(getStressAnalysisForCorrelation(newFilters));
    }, [filters, selectedCorrelationType?.id]);

    const handleCorrelationTypeChange = (typeId) => {
        const correlationType = correlationTypes.find((type) => type.id === typeId);
        setSelectedCorrelationType(correlationType);
    };

    const getFormatterFunction = (category) => {
        switch (category) {
            case CORRELATION_CATEGORY.SUM_EXTREMELY_UNACCEPTABLE_STRESS_ZONE_TIME_PER_THROW:
            case CORRELATION_CATEGORY.SUM_HIGH_STRESS_ZONE_TIME_PER_EVENT:
            case CORRELATION_CATEGORY.SUM_CROWDING_TIME_PER_THROW:
                return formatSeconds;
            case CORRELATION_CATEGORY.AVERAGE_OXYGEN_LEVEL_PER_THROW:
                return formatNumber;
            default:
                return;
        }
    };

    return (
        <>
            <div className="mt-5 flex xs:flex-col xl:flex-row">
                <h2 className="mb-0 mr-2">{t('stressAnalysis.correlationAnalysis')}</h2>
                <FDSelection
                    listSelectItem={correlationTypes.map((type) => ({
                        id: type.id,
                        text: `${t(`stressAnalysis.correlationType.${type.categoryX}`)} - ${t(
                            `stressAnalysis.correlationType.${type.categoryY}`
                        )}`
                    }))}
                    loading={correlationTypesLoading}
                    value={selectedCorrelationType?.id}
                    onChange={handleCorrelationTypeChange}
                />
            </div>

            <Row gutter={[8, 8]} className="mt-2">
                <Col xs={24} xl={12}>
                    <Spin spinning={regressionsLoading}>
                        <Card>
                            <p className="font-medium text-base">
                                {t('stressAnalysis.linearRegression')}
                            </p>
                            <FDScatter
                                data={regressions}
                                height={340}
                                xAxisTitle={
                                    selectedCorrelationType?.categoryX &&
                                    `${t(
                                        'stressAnalysis.correlationType.' +
                                            selectedCorrelationType?.categoryX
                                    )}`
                                }
                                yAxisTitle={
                                    selectedCorrelationType?.categoryY &&
                                    `${t(
                                        'stressAnalysis.correlationType.' +
                                            selectedCorrelationType?.categoryY
                                    )}`
                                }
                                xAxisFormatter={getFormatterFunction(
                                    selectedCorrelationType?.categoryX
                                )}
                                yAxisFormatter={getFormatterFunction(
                                    selectedCorrelationType?.categoryY
                                )}
                            />
                        </Card>
                    </Spin>
                </Col>
                <Col xs={24} xl={12}>
                    <Card className="h-full">
                        <Spin spinning={correlationLoading}>
                            <p className="font-medium text-base">
                                {t('stressAnalysis.pearsonCorrelationCoefficient')}
                            </p>

                            <div className="correlation-chart">
                                <FDBullet
                                    data={[
                                        {
                                            ranges: [-1, 0],
                                            measures: [0],
                                            target: correlation.value
                                        }
                                    ]}
                                    height={100}
                                    yAxis={{
                                        minLimit: -1,
                                        maxLimit: 1
                                    }}
                                    formatValue={formatNumber}
                                />
                                <div className="flex justify-between gap-4">
                                    <span className="text-center">
                                        {t('stressAnalysis.strongNegativeCorrelation')}
                                    </span>
                                    <span className="text-center">
                                        {t('stressAnalysis.noCorrelation')}
                                    </span>
                                    <span className="text-center">
                                        {t('stressAnalysis.strongPositiveCorrelation')}
                                    </span>
                                </div>
                            </div>

                            <div className="correlation-description mt-12">
                                <div>
                                    <b className="mr-1">
                                        {t('stressAnalysis.strongNegativeCorrelation')}:
                                    </b>
                                    <span>
                                        {t('stressAnalysis.strongNegativeCorrelationDescription')}
                                    </span>
                                </div>
                                <div>
                                    <b className="mr-1">{t('stressAnalysis.noCorrelation')}:</b>
                                    <span>{t('stressAnalysis.noCorrelationDescription')}</span>
                                </div>
                                <div>
                                    <b className="mr-1">
                                        {t('stressAnalysis.strongPositiveCorrelation')}:
                                    </b>
                                    <span>
                                        {t('stressAnalysis.strongPositiveCorrelationDescription')}
                                    </span>
                                </div>
                            </div>
                        </Spin>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default CorrelationAnalysis;
