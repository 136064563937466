import { Card } from 'antd';
import { GRAY, GREEN, ORANGE, RED, STRESS_ZONES, YELLOW } from 'common/constants';
import { formatSeconds, getDifferenceInSeconds, isTimeWithinSliderValues } from 'common/utils';
import { FDBar } from 'components';
import { map, reduce } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { stressLevelSelector } from 'redux/selector';

const StressZone = ({ sliderValues }) => {
    const { t } = useTranslation();

    const stressLevels = useSelector(stressLevelSelector);
    const event = useSelector((s) => s.eventDetail.data);
    const [chartData, setChartData] = useState([]);

    useEffect(() => {
        const filteredStressLevels = reduce(
            stressLevels,
            (accumulator, stress) => {
                const differenceInSeconds = getDifferenceInSeconds(
                    event.startTime,
                    stress.stop_time
                );

                if (
                    isTimeWithinSliderValues(sliderValues, differenceInSeconds) &&
                    isTimeWithinEventThrowTimes(differenceInSeconds)
                )
                    accumulator.push({
                        totalSeconds: getDifferenceInSeconds(stress.start_time, stress.stop_time),
                        value: stress.level
                    });

                return accumulator;
            },
            []
        );

        const filteredData = map(STRESS_ZONES, (stressZone) => ({
            name: t(stressZone.name),
            value: filteredStressLevels
                ?.filter(
                    (stressLevel) =>
                        stressZone.min <= stressLevel.value && stressLevel.value <= stressZone.max
                )
                .reduce((accumulator, stressLevel) => accumulator + stressLevel.totalSeconds, 0)
        }));

        setChartData(filteredData);
    }, [sliderValues, t]);

    const eventThrowTimes = event.throws?.map((eventThrow) => ({
        startTime: getDifferenceInSeconds(event.startTime, eventThrow.startTime),
        stopTime: getDifferenceInSeconds(event.startTime, eventThrow.stopTime)
    }));

    const isTimeWithinEventThrowTimes = (seconds) =>
        isEmpty(eventThrowTimes) ||
        eventThrowTimes.some(
            (eventThrow) => eventThrow.startTime <= seconds && seconds <= eventThrow.stopTime
        );

    return (
        <Card className="mt-2">
            <p className="font-medium text-base">{t('eventDetail.stressZone')}</p>
            <FDBar
                data={chartData}
                colors={[GRAY, RED, ORANGE, YELLOW, GREEN]}
                height={300}
                formatValue={formatSeconds}
                chartConfig={{
                    animation: false
                }}
            />
        </Card>
    );
};

StressZone.propTypes = {
    sliderValues: PropTypes.array
};

export default StressZone;
