import { Card, Spin } from 'antd';
import { formatNumber } from 'common/utils';
import { FDRangeArea } from 'components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const StressLevelPerThrow = () => {
    const { t } = useTranslation();

    const { data: stressLevelsPerThrow, loading: stressLevelsPerThrowLoading } = useSelector(
        (s) => s.stressAnalysis.stressLevelPerThrow
    );

    const formattedData = {
        areas: stressLevelsPerThrow.map((item, index) => ({
            name: String(index + 1),
            value: [item.lowest, item.highest]
        })),
        lines: stressLevelsPerThrow.map((item, index) => ({
            name: String(index + 1),
            value: item.average
        }))
    };

    return (
        <Spin spinning={stressLevelsPerThrowLoading}>
            <Card>
                <p className="font-medium text-base">
                    {t('stressAnalysis.averageStressLevelPerThrow')}
                </p>
                <FDRangeArea
                    data={formattedData}
                    tooltipLabel={{
                        area: `${t('stressAnalysis.lowest')}-${t('stressAnalysis.highest')}`,
                        line: t('stressAnalysis.average')
                    }}
                    chartConfig={{
                        yAxis: {
                            max: 100,
                            min: 0
                        }
                    }}
                    yAxisFormatter={formatNumber}
                />
            </Card>
        </Spin>
    );
};

export default StressLevelPerThrow;
