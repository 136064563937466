import { Col, Row, Spin } from 'antd';
import { useSelector } from 'react-redux';
import { mortalityDetailSelector } from 'redux/selector';
import MortalityCount from './MortalityCount';
import MortalityInfo from './MortalityInfo';
import WoundAttachment from './WoundAttachment';
import WoundCount from './WoundCount';

const MortalityDetail = () => {
    const { loading: mortalityDetailLoading } = useSelector(mortalityDetailSelector);
    const { loading: mortalityLoading } = useSelector((s) => s.mortality.detailList);
    const { selectedMortalityId } = useSelector((s) => s.filter.data);

    if (!selectedMortalityId) return;

    return (
        <Spin spinning={mortalityDetailLoading || mortalityLoading}>
            <Row gutter={8}>
                <Col xs={24}>
                    <MortalityInfo />
                </Col>
                <Col xs={24} xl={8}>
                    <MortalityCount />
                </Col>
                <Col xs={24} xl={16}>
                    <WoundCount />
                </Col>
                <Col xs={24}>
                    <WoundAttachment />
                </Col>
            </Row>
        </Spin>
    );
};

export default MortalityDetail;
