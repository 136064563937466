import { withPageViewTracking } from 'hoc';
import FilterBar from './FilterBar';
import ScoringDetail from './ScoringDetail/ScoringDetail';

const ScoringEvent = () => {
    return (
        <>
            <FilterBar />

            <ScoringDetail />
        </>
    );
};

export default withPageViewTracking(ScoringEvent, 'Scoring event detail');
