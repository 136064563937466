import { appInsights } from 'AppInsights';
import { FDFilter } from 'components';
import { withPageViewTracking } from 'hoc';
import { useEffect } from 'react';
import { useAppDispatch } from 'redux/store';
import {
    getFishwellFishIdsSelection,
    getMortalityList,
    getPensSelection,
    getSitesSelection
} from 'redux/thunks';
import TableMortalityList from './TableMortalityList';

const MortalityList = () => {
    const dispatch = useAppDispatch();

    const handleFilterSubmit = (query) => {
        dispatch(getMortalityList(query));

        appInsights.trackEvent({ name: 'Mortality list filter apply' });
    };

    useEffect(() => {
        dispatch(getFishwellFishIdsSelection());
        dispatch(getSitesSelection());
        dispatch(getPensSelection());
    }, []);

    return (
        <>
            <FDFilter hasSite hasPen hasFishGroupIds hasDateRange callback={handleFilterSubmit} />
            <TableMortalityList />
        </>
    );
};

export default withPageViewTracking(MortalityList, 'Mortality list');
